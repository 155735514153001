main {
  &[data-bg="peach"] {
    background-color: var(--color-peach);
  }
  &[data-bg="purple"] {
    background-color: var(--color-purple);
  }
  &[data-bg="blue"] {
    background-color: var(--color-blue);
  }
}

#transition-overlay {
  position: fixed;
  inset: 0;
  // width: 100%;
  // height: 100dvh;
  pointer-events: none;
}

#main-overlay {
  position: fixed;
  inset: 0;
}

#loader {
  position: fixed;
  inset: 0;
  width: 100%;
  z-index: 100;
  display: grid;
  align-items: center;
  justify-content: center;
  color: white;

  .loader-title {
    font-family: var(--font-display);
    font-size: 70px;
    letter-spacing: 1.5;
    position: relative;
  }

  .spinner {
    position: relative;

    svg {
      width: 75px;
    }
  }
}

// breakpoints
@include below(md) {
  #loader {
    .loader-title {
      font-size: 60px;
    }
  }
}
menu {
  width: var(--menu-width);
  height: var(--menu-width);
  transition: .3s transform ease, .45s height cubic-bezier(0.58, -0.5, 0.28, 1.5);
  top: var(--menu-gap);
  right: var(--menu-gap);
  position: fixed;
  border-radius: 100px;
  display: block;
  overflow: hidden;
  z-index: 10;
  background-color: white;
  box-shadow: 0 10px 10px rgba(0,0,0,.15);
  box-sizing: content-box;

  &.is-active {
    height: 279px;

    .menu-list {
      opacity: 1;
      transition-delay: .3s;
    }

    .menu-trigger {
      #center {
        width: 0;
      }
      #top-wrapper {
        transform: rotate(45deg);
      }
      #top {
        transform: translateY(26.5%);
      }
      #bottom-wrapper {
        transform: rotate(-45deg);
      }
      #bottom {
        transform: translateY(-27%);
      }
    }
  }
}

#edit-trigger {
  visibility: hidden;
  width: var(--menu-width);
  height: var(--menu-width);
  padding: var(--menu-item-padding);
  display: grid;
  align-items: center;
  position: fixed;
  top: var(--menu-gap);
  right: 100px;
  background-color: var(--color-peach-light);
  border-radius: 50%;
  cursor: pointer;
  div {
    margin: auto;
  }
}

.menu-trigger {
  cursor: pointer;
  .inner {
    width: var(--menu-width);
    height: var(--menu-width);
    padding: var(--menu-item-padding);
    display: grid;
    align-items: center;
  }
  svg {
    display: block;
    width: var(--menu-icon-width);
    margin: auto;
  }
  #bg {
    opacity: 0;
  }
  g {
    transform-origin: center;
  }
  #center {
    transition: .3s width cubic-bezier(0.58, -0.5, 0.28, 1.5);
  }
  #top-wrapper {
    transition: .3s transform cubic-bezier(0.58, -0.5, 0.28, 1.5);
  }
  #top {
    transition: .3s transform cubic-bezier(0.58, -0.5, 0.28, 1.5);
  }
  #bottom-wrapper {
    transition: .3s transform cubic-bezier(0.58, -0.5, 0.28, 1.5);
  }
  #bottom {
    transition: .3s transform cubic-bezier(0.58, -0.5, 0.28, 1.5);
  }
}

.menu-list {
  opacity: 0;
}

.social-list {
  visibility: hidden;
}

.menu-list, .social-list {
  transition: .3s all ease;
  position: absolute;
  top: var(--menu-width);
  left: 0;
  width: 100%;
  grid-gap: var(--menu-list-gap);
  padding: var(--menu-item-padding);
  display: grid;
  a {
    display: grid;
    align-items: center;
    width: var(--menu-item-width);
    height: var(--menu-item-width);
    border-radius: 50%;
    background-color: #eee;
    transform-origin: center;
    transition: .3s all ease;
    svg {
      display: block;
      width: var(--menu-icon-width);
      margin: auto;
      transition: .3s opacity ease;
      // opacity: .5;
    }
  }
  a.is-active {
    background-color: var(--color-peach);
    svg {
      opacity: 1;
    }
  }
  a:hover {
    svg {
      opacity: 1;
    }
  }
  .email-mobile {
    display: none;
  }
}

@include above(md) {
  menu:not(.is-active):hover {
    transform: scale(1.1);
  }
  .menu-list {
    .resume {
      display: none;
    }
    a:hover {
      transform: scale(1.15);
    }
  }
}

@include below(md) {
  menu {
    bottom: var(--menu-gap);
    left: 50%;
    right: unset;
    top: unset;
    margin-left: -146.5px;
    width: 293px;
    box-shadow: 0 5px 5px #00000015;
    height: var(--menu-width) !important;
  }
  .menu-trigger {
    display: none;
  }
  .menu-items {
    display: flex;
    overflow: scroll;
    overscroll-behavior-x: contain;
  }
  .menu-list, .social-list {
    opacity: 1;
    position: relative;
    top: 0;
    display: flex;
    grid-gap: 7px;
    .email-desktop {
      display: none;
    }
    .email-mobile {
      display: block;
    }
  }
  .social-list {
    visibility: visible;
    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background-color: #ddd;
      transform: scaleY(.75);
      margin-right: var(--menu-item-padding);
    }
  }
}




















/* ============== OLD ================ */
#old-header {
  --nav-right: 25px;
  
  visibility: hidden;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  .container {
    display: grid;
    height: var(--header-height);
    align-items: center;
  }

  .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  nav {
    min-width: 200px;
    display: flex;
    font-size: 18px;
    letter-spacing: .5px;
    font-weight: 700;

    > * {
      cursor: pointer;
      padding: 20px var(--nav-right);
      // border-bottom: 3px solid black;
    }
  }

  .left-nav {
    justify-content: flex-end;
  }

  .logo {
    margin: 0 var(--nav-right);
    img, svg {
      width: 50px;
      display: block;
    }
  }
  
}

// breakpoints
@include below(md) {
  header {
    display: none;
  }
}
// "browserslist": "> 0.5%, last 3 versions, not dead, Safari 14",
// add the code above below devDependencies to fix media queries issue

#home {
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;
  }

  .content-wrapper {
    padding-top: 14dvh;
    display: grid;
    text-align: center;
  }
}

.main-title-wrapper {
  position: relative;
  height: fit-content;
  #stars {
    position: absolute;
    top: 0;
    left: 50%;
    width: 25%;
    height: 100%;
    margin-left: -12.5%;
  }
  svg {
    width: 20%;
    position: absolute;
    top: 16%;
    right: 7%;
  }
}

#main-title {
  font-family: var(--font-display);
  color: var(--color-peach-light);
  margin: auto auto 0;
  display: inline-block;
  font-size: 12.25vw;
  pointer-events: none;
}

#credits {
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 1;

  .credits-trigger {
    pointer-events: auto;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 10px 20px;
    font-size: 14px;
    background-color: var(--color-peach-light);
    font-weight: 700;
    border-top-right-radius: 15px;
    cursor: pointer;
  }
}

// breakpoints
@include below(lg) {
  #main-title {
    font-size: 20vw;
  }
}

@include below(md) {
  #credits {
    .credits-trigger {
      bottom: unset;
      left: unset;
      right: 0;
      top: 0;
      border-top-right-radius: unset;
      border-bottom-left-radius: 15px;
    }
  }

  #home {
    .content-wrapper {
      padding-top: var(--page-padding-y-sm);
    }
  }
}
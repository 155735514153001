.container {
  margin-left: auto;
  margin-right: auto;
}

@include below(md) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@include above(md) {
  .container {
    width: 80vw;
  }
}
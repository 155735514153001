:root {
  /* based on https://colorhunt.co/palette/fde5ecfcbaade48586916db3 */
  --color-shirt: #8B96A7;
  --color-peach: #ffdde6;
  --color-peach-light: #ef99b1;
  --color-peach-dark: #f67499;
  --color-purple: #fef2ff;
  --color-purple-dark: #d5a7d9;
  --font-display: "Anton", sans-serif;
  --font-body: "Nunito", sans-serif;

  --menu-width: 60px;
  --menu-item-width: 46px;
  --menu-item-padding: 7px; // menu width - menu item width
  --menu-icon-width: 55%;
  --menu-list-gap: 7px;
  --menu-gap: 25px;
  --menu-gap-mobile: 50px;

  --page-padding-y: 125px;
  --page-padding-y-sm: 100px;
  --page-padding-x: 30px;

  --about-padding-y: 125px;
  --about-padding-y-sm: 70px;
  --about-padding-x: 25px;
  --about-content-width: 700px;
}
/*! Flickity v3.0.0
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  touch-action: pan-y;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-slider {
  left: unset;
  right: 0;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing;
}

.flickity-cell {
  position: absolute;
  left: 0;
}

.flickity-rtl .flickity-cell {
  left: unset;
  right: 0;
}

.flickity-button {
  color: #333;
  background: #ffffffbf;
  border: none;
  position: absolute;
}

.flickity-button:hover {
  cursor: pointer;
  background: #fff;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: .6;
}

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-prev-next-button {
  border-radius: 50%;
  width: 44px;
  height: 44px;
  top: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  left: 10px;
  right: auto;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flickity-page-dots {
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: -25px;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dot {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  text-indent: -9999px;
  background: #33333340;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  padding: 0;
  display: block;
  overflow: hidden;
}

.flickity-rtl .flickity-page-dot {
  text-indent: 9999px;
}

.flickity-page-dot:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-page-dot.is-selected {
  background: #333;
}

*, :before, :after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root {
  --color-shirt: #8b96a7;
  --color-peach: #ffdde6;
  --color-peach-light: #ef99b1;
  --color-peach-dark: #f67499;
  --color-purple: #fef2ff;
  --color-purple-dark: #d5a7d9;
  --font-display: "Anton", sans-serif;
  --font-body: "Nunito", sans-serif;
  --menu-width: 60px;
  --menu-item-width: 46px;
  --menu-item-padding: 7px;
  --menu-icon-width: 55%;
  --menu-list-gap: 7px;
  --menu-gap: 25px;
  --menu-gap-mobile: 50px;
  --page-padding-y: 125px;
  --page-padding-y-sm: 100px;
  --page-padding-x: 30px;
  --about-padding-y: 125px;
  --about-padding-y-sm: 70px;
  --about-padding-x: 25px;
  --about-content-width: 700px;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 80vw;
  }
}

main[data-bg="peach"] {
  background-color: var(--color-peach);
}

main[data-bg="purple"] {
  background-color: var(--color-purple);
}

main[data-bg="blue"] {
  background-color: var(--color-blue);
}

#transition-overlay {
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#main-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#loader {
  z-index: 100;
  color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: grid;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#loader .loader-title {
  font-family: var(--font-display);
  letter-spacing: 1.5px;
  font-size: 70px;
  position: relative;
}

#loader .spinner {
  position: relative;
}

#loader .spinner svg {
  width: 75px;
}

@media screen and (max-width: 767px) {
  #loader .loader-title {
    font-size: 60px;
  }
}

menu {
  width: var(--menu-width);
  height: var(--menu-width);
  top: var(--menu-gap);
  right: var(--menu-gap);
  z-index: 10;
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 100px;
  transition: transform .3s, height .45s cubic-bezier(.58, -.5, .28, 1.5);
  display: block;
  position: fixed;
  overflow: hidden;
  box-shadow: 0 10px 10px #00000026;
}

menu.is-active {
  height: 279px;
}

menu.is-active .menu-list {
  opacity: 1;
  transition-delay: .3s;
}

menu.is-active .menu-trigger #center {
  width: 0;
}

menu.is-active .menu-trigger #top-wrapper {
  transform: rotate(45deg);
}

menu.is-active .menu-trigger #top {
  transform: translateY(26.5%);
}

menu.is-active .menu-trigger #bottom-wrapper {
  transform: rotate(-45deg);
}

menu.is-active .menu-trigger #bottom {
  transform: translateY(-27%);
}

#edit-trigger {
  visibility: hidden;
  width: var(--menu-width);
  height: var(--menu-width);
  padding: var(--menu-item-padding);
  top: var(--menu-gap);
  background-color: var(--color-peach-light);
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  display: grid;
  position: fixed;
  right: 100px;
}

#edit-trigger div {
  margin: auto;
}

.menu-trigger {
  cursor: pointer;
}

.menu-trigger .inner {
  width: var(--menu-width);
  height: var(--menu-width);
  padding: var(--menu-item-padding);
  align-items: center;
  display: grid;
}

.menu-trigger svg {
  width: var(--menu-icon-width);
  margin: auto;
  display: block;
}

.menu-trigger #bg {
  opacity: 0;
}

.menu-trigger g {
  transform-origin: center;
}

.menu-trigger #center {
  transition: width .3s cubic-bezier(.58, -.5, .28, 1.5);
}

.menu-trigger #top-wrapper, .menu-trigger #top, .menu-trigger #bottom-wrapper, .menu-trigger #bottom {
  transition: transform .3s cubic-bezier(.58, -.5, .28, 1.5);
}

.menu-list {
  opacity: 0;
}

.social-list {
  visibility: hidden;
}

.menu-list, .social-list {
  top: var(--menu-width);
  grid-gap: var(--menu-list-gap);
  padding: var(--menu-item-padding);
  width: 100%;
  transition: all .3s;
  display: grid;
  position: absolute;
  left: 0;
}

.menu-list a, .social-list a {
  width: var(--menu-item-width);
  height: var(--menu-item-width);
  transform-origin: center;
  background-color: #eee;
  border-radius: 50%;
  align-items: center;
  transition: all .3s;
  display: grid;
}

.menu-list a svg, .social-list a svg {
  width: var(--menu-icon-width);
  margin: auto;
  transition: opacity .3s;
  display: block;
}

.menu-list a.is-active, .social-list a.is-active {
  background-color: var(--color-peach);
}

.menu-list a.is-active svg, .social-list a.is-active svg, .menu-list a:hover svg, .social-list a:hover svg {
  opacity: 1;
}

.menu-list .email-mobile, .social-list .email-mobile {
  display: none;
}

@media screen and (min-width: 768px) {
  menu:not(.is-active):hover {
    transform: scale(1.1);
  }

  .menu-list .resume {
    display: none;
  }

  .menu-list a:hover {
    transform: scale(1.15);
  }
}

@media screen and (max-width: 767px) {
  menu {
    bottom: var(--menu-gap);
    left: 50%;
    right: unset;
    top: unset;
    width: 293px;
    margin-left: -146.5px;
    box-shadow: 0 5px 5px #00000015;
    height: var(--menu-width) !important;
  }

  .menu-trigger {
    display: none;
  }

  .menu-items {
    overscroll-behavior-x: contain;
    display: flex;
    overflow: scroll;
  }

  .menu-list, .social-list {
    opacity: 1;
    grid-gap: 7px;
    display: flex;
    position: relative;
    top: 0;
  }

  .menu-list .email-desktop, .social-list .email-desktop {
    display: none;
  }

  .menu-list .email-mobile, .social-list .email-mobile {
    display: block;
  }

  .social-list {
    visibility: visible;
  }

  .social-list:before {
    content: "";
    margin-right: var(--menu-item-padding);
    background-color: #ddd;
    width: 1px;
    height: 100%;
    display: block;
    transform: scaleY(.75);
  }
}

#old-header {
  --nav-right: 25px;
  visibility: hidden;
  z-index: 10;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

#old-header .container {
  height: var(--header-height);
  align-items: center;
  display: grid;
}

#old-header .content {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

#old-header nav {
  letter-spacing: .5px;
  min-width: 200px;
  font-size: 18px;
  font-weight: 700;
  display: flex;
}

#old-header nav > * {
  cursor: pointer;
  padding: 20px var(--nav-right);
}

#old-header .left-nav {
  justify-content: flex-end;
}

#old-header .logo {
  margin: 0 var(--nav-right);
}

#old-header .logo img, #old-header .logo svg {
  width: 50px;
  display: block;
}

@media screen and (max-width: 767px) {
  header {
    display: none;
  }
}

@keyframes squiggly {
  0% {
    filter: url("#squiggly-0");
  }

  25% {
    filter: url("#squiggly-1");
  }

  50% {
    filter: url("#squiggly-2");
  }

  75% {
    filter: url("#squiggly-3");
  }

  100% {
    filter: url("#squiggly-4");
  }
}

#squiggly {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

#rive {
  width: 100%;
  padding-bottom: 10vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

#rive:before {
  content: "";
  aspect-ratio: 1;
  width: 25%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#rive.show-sc-only .canvas-wrapper:not(#character) {
  visibility: hidden;
}

#rive .inner {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}

#rive canvas:not(#transition-overlay) {
  width: 100%;
  position: relative;
}

#rive canvas:not(.artboard-sc):not(.artboard-music) {
  pointer-events: none;
}

#rive .look-trigger, #rive .page-trigger {
  opacity: 0;
  background-color: red;
  border-radius: 3%;
  position: absolute;
}

.canvas-wrapper {
  position: relative;
}

.canvas-wrapper:after {
  content: "";
  background-color: red;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.canvas-wrapper:not(#character) {
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
}

#character {
  transform-origin: bottom;
  position: relative;
}

#character.is-jumping #shirt-trigger {
  display: none;
}

#character #shirt-trigger {
  opacity: 0;
  cursor: pointer;
  background-color: red;
  border-top-left-radius: 15%;
  border-top-right-radius: 15%;
  width: 17%;
  height: 16%;
  position: absolute;
  top: 61%;
  left: 50%;
  transform: translateX(-50%);
}

#character #floor {
  transform-origin: center;
  z-index: -1;
  background-color: #00000026;
  border-radius: 10px;
  height: 1.15%;
  position: absolute;
  bottom: 0;
  left: -110%;
  right: -110%;
}

#bubbles {
  display: none;
  position: absolute;
  top: 15%;
  left: -25%;
  right: -25%;
}

#bubbles .bubble {
  color: #fff;
  background-color: #248bf5;
  border-radius: 100px 100px 0;
  padding: 15px 30px;
  font-size: 20px;
  display: inline-block;
}

#desk.canvas-wrapper {
  left: -105%;
}

#desk.canvas-wrapper .email-trigger {
  border-radius: 10px;
  width: 23%;
  height: 14%;
  top: 7%;
  left: 45%;
  right: 20%;
}

#desk.canvas-wrapper .work-trigger {
  width: 62%;
  height: 31.85%;
  padding: 3px 10px 3px 4px;
  transition: transform .3s cubic-bezier(.34, 2.14, .64, 1);
  top: 27.25%;
  left: 20%;
}

#desk.canvas-wrapper .work-trigger:hover {
  transition: transform .5s cubic-bezier(.16, 1, .3, 1);
  transform: translateY(-4%);
}

#desk.canvas-wrapper .screen {
  object-fit: cover;
  opacity: 0;
  height: 100%;
  transition: all .3s;
  position: relative;
}

#desk.canvas-wrapper .content {
  color: #fff;
  letter-spacing: 2px;
  font-size: 200%;
}

#desk.canvas-wrapper.is-monitor-on .screen {
  opacity: 1;
}

#music.canvas-wrapper {
  left: unset;
  right: -92.5%;
}

#music.canvas-wrapper .guitar-wrapper {
  opacity: 0;
  width: 24.15%;
  position: absolute;
  top: 2.2%;
  left: 7.7%;
}

#music.canvas-wrapper .sound-trigger {
  cursor: pointer;
  border-radius: 10px;
  width: 15.1%;
  height: 20%;
  top: 46.5%;
  left: 57.3%;
}

#social-resume.canvas-wrapper {
  bottom: unset;
  left: unset;
  width: 62.5%;
  top: -40%;
  right: -120%;
}

#social-resume.canvas-wrapper .social-links {
  width: 91%;
  height: 27%;
  position: absolute;
  top: 8%;
  left: 0%;
}

#social-resume.canvas-wrapper .social-links .page-trigger {
  border-radius: 10%;
  width: 24%;
  height: 52%;
  top: 24%;
  left: 9.5%;
}

#social-resume.canvas-wrapper .social-links .page-trigger:nth-child(2) {
  left: 38%;
}

#social-resume.canvas-wrapper .social-links .page-trigger:nth-child(3) {
  left: 66.5%;
}

#social-resume.canvas-wrapper .resume-trigger {
  border-radius: 7%;
  width: 50%;
  height: 25%;
  transition: transform .3s cubic-bezier(.34, 2.14, .64, 1);
  bottom: 4.5%;
  left: 21.5%;
}

#social-resume.canvas-wrapper .resume-trigger:hover {
  transition: transform .5s cubic-bezier(.16, 1, .3, 1);
  transform: translateY(-6%);
}

#about-sc.canvas-wrapper {
  bottom: unset;
  left: -90%;
  right: unset;
  width: 50%;
  top: -50%;
}

#about-sc.canvas-wrapper .about-trigger {
  top: 29%;
  bottom: 19%;
  left: 22%;
  right: 22%;
}

@media screen and (max-width: 768px) {
  #rive {
    padding-bottom: 18vh;
    padding-bottom: 18dvh;
  }

  #rive .inner {
    width: 80%;
  }
}

@media screen and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  #rive {
    padding-bottom: 8vh;
  }

  #rive .inner {
    width: 70%;
  }
}

@media (orientation: portrait) or (max-width: 768px) {
  .canvas-wrapper:not(#character) {
    visibility: hidden;
  }

  #character #floor {
    left: 20%;
    right: 20%;
  }
}

#home {
  flex-wrap: wrap;
  display: flex;
}

#home > div {
  width: 100%;
}

#home .content-wrapper {
  text-align: center;
  padding-top: 14dvh;
  display: grid;
}

.main-title-wrapper {
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}

.main-title-wrapper #stars {
  width: 25%;
  height: 100%;
  margin-left: -12.5%;
  position: absolute;
  top: 0;
  left: 50%;
}

.main-title-wrapper svg {
  width: 20%;
  position: absolute;
  top: 16%;
  right: 7%;
}

#main-title {
  font-family: var(--font-display);
  color: var(--color-peach-light);
  pointer-events: none;
  margin: auto auto 0;
  font-size: 12.25vw;
  display: inline-block;
}

#credits {
  pointer-events: none;
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#credits .credits-trigger {
  pointer-events: auto;
  background-color: var(--color-peach-light);
  cursor: pointer;
  border-top-right-radius: 15px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 1023px) {
  #main-title {
    font-size: 20vw;
  }
}

@media screen and (max-width: 767px) {
  #credits .credits-trigger {
    bottom: unset;
    left: unset;
    border-top-right-radius: unset;
    border-bottom-left-radius: 15px;
    top: 0;
    right: 0;
  }

  #home .content-wrapper {
    padding-top: var(--page-padding-y-sm);
  }
}

main[data-barba-namespace="work"] + #rive {
  pointer-events: none;
}

#work #work-title {
  letter-spacing: -1px;
  margin-bottom: 60px;
  font-size: 7vw;
}

#work .inner {
  padding: 50px 50px 200px;
}

.work-grid {
  --card-gap: 1vw;
  grid-gap: var(--card-gap);
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  display: grid;
}

.cards {
  grid-gap: var(--card-gap);
  align-items: flex-start;
  display: grid;
  position: relative;
}

.card {
  color: #fff;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 5px #e9bfcb85;
}

.card:after {
  content: "";
  opacity: 0;
  pointer-events: none;
  background-color: #000;
  transition: all .3s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card.is-dim:after {
  opacity: .75;
}

.card.is-loaded .card-tags, .card.is-loaded .card-details:before, .card.is-loaded video {
  visibility: visible;
}

.card.theme-dark .card-details {
  color: #fff;
}

.card.theme-dark.is-loaded .card-details:before {
  visibility: hidden;
}

.card.theme-light .card-details {
  color: #000;
}

.card.theme-light.is-loaded .card-details:before {
  visibility: hidden;
}

.card .card-info {
  justify-content: space-between;
  display: flex;
}

.card .card-tags {
  visibility: hidden;
  margin-bottom: 10px;
  display: inline-flex;
}

.card .card-tags .pill:not(:last-child) {
  margin-right: 10px;
}

.card .pill {
  color: #fff;
  opacity: 0;
  text-transform: capitalize;
  border-radius: 50px;
  padding: 4px 15px;
  font-size: 12px;
  transition: all .3s;
  display: inline-block;
  transform: translateY(5px);
}

.card .type-link {
  background-color: #fff;
  align-items: center;
  width: 26px;
  height: 26px;
  padding: 0;
  display: grid;
}

.card .type-link svg {
  transform-origin: center;
  width: 13px;
  margin: auto;
  display: block;
  transform: rotate(135deg);
}

.card .type-website {
  background-color: var(--color-peach-dark);
}

.card .type-prototype {
  background-color: #c97ccf;
}

.card .card-details {
  padding: 15px 20px;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.card .card-details div {
  position: relative;
}

.card .card-details:before {
  content: "";
  pointer-events: none;
  transform-origin: center;
  visibility: hidden;
  background-image: linear-gradient(to top, #000000e6, #0000);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleY(1.5);
}

.card video, .card img {
  object-fit: cover;
  width: 100%;
  display: block;
}

.card .thumbnail {
  filter: blur(32px);
  transform: scale(1)translateZ(0);
}

.card video {
  visibility: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 768px) {
  .card:hover .pill {
    opacity: 1;
    transform: none;
  }

  .card:hover .pill:nth-child(2) {
    transition-delay: .1s;
  }
}

@media screen and (max-width: 1023px) {
  #work .inner {
    padding: 25px;
  }

  .work-grid {
    --card-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  #work {
    padding-bottom: 100px;
  }

  #work .inner {
    padding: 15px;
  }

  .work-grid {
    --card-gap: 15px;
    grid-template-columns: repeat(1, 1fr);
  }

  .work-grid .card {
    border-radius: 10px;
  }
}

#about {
  padding: var(--about-padding-y) var(--about-padding-x);
  position: relative;
}

#about .content-wrapper {
  width: var(--about-content-width);
  margin-left: auto;
  margin-right: auto;
}

#about h1 {
  text-transform: uppercase;
  font-size: 4vw;
  line-height: 1.25;
  font-family: var(--font-display);
  letter-spacing: 1.2px;
  margin-bottom: 40px;
  font-weight: 900;
}

#about article p {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.65;
}

#about article p:not(:last-child) {
  margin-bottom: 35px;
}

#about .header {
  flex-wrap: wrap;
  margin-bottom: 60px;
  display: flex;
}

#about .header .avatar-wrapper {
  aspect-ratio: 1;
  border-radius: 50%;
  width: 32%;
  display: block;
}

#about .header .avatar-info {
  align-items: center;
  width: 68%;
  padding-left: 50px;
  display: grid;
}

#about .header .name {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.2;
}

#about .header .real-name {
  color: #666;
  cursor: help;
  align-items: center;
  font-size: 25px;
  font-weight: 600;
  line-height: 1.5;
  display: inline-flex;
}

#about .header .real-name svg {
  width: 20px;
  margin-left: 10px;
  display: block;
  position: relative;
  top: 1px;
}

#about .header .details {
  margin-top: 20px;
  font-size: 22px;
  font-weight: 400;
}

main[data-barba-namespace="about"] {
  position: relative;
}

main[data-barba-namespace="about"] + #rive {
  pointer-events: none;
  align-items: center;
  height: 100%;
  padding-bottom: 0;
  display: grid;
  top: 0;
}

main[data-barba-namespace="about"] + #rive .inner {
  width: 50%;
}

main[data-barba-namespace="about"] + #rive #floor {
  opacity: 0;
  left: 20%;
  right: 20%;
}

main[data-barba-namespace="about"] + #rive.is-ready {
  height: unset;
  bottom: unset;
  padding: var(--about-padding-y) var(--about-padding-x);
  position: absolute;
  top: 0;
  overflow: hidden;
}

main[data-barba-namespace="about"] + #rive.is-ready .inner {
  width: var(--about-content-width);
  margin-left: auto;
  margin-right: auto;
}

main[data-barba-namespace="about"] + #rive.is-ready #character {
  pointer-events: auto;
  width: 32%;
}

main[data-barba-namespace="about"] + #rive.is-ready #character canvas {
  -webkit-clip-path: polygon(25% 1%, 75% 0, 75% 80%, 25% 80%);
  clip-path: polygon(25% 1%, 75% 0, 75% 80%, 25% 80%);
  background-color: var(--color-peach);
  width: 200%;
  margin-top: -50%;
  margin-left: -50%;
  position: relative;
}

@media screen and (max-width: 1023px) {
  #about .content-wrapper, main[data-barba-namespace="about"] + #rive .inner, main[data-barba-namespace="about"] + #rive.is-ready .inner {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  #about .content-wrapper, main[data-barba-namespace="about"] + #rive .inner, main[data-barba-namespace="about"] + #rive.is-ready .inner {
    width: 100%;
  }
}

@media screen and (max-width: 639px) {
  #about {
    padding-top: var(--about-padding-y-sm);
    padding-bottom: 140px;
  }

  #about .header {
    margin-bottom: 50px;
    display: block;
  }

  #about .header .avatar-wrapper {
    width: 50%;
    margin-bottom: 30px;
  }

  #about .header .avatar-info {
    width: 100%;
    padding-left: 0;
  }

  #about .header .name {
    font-size: 35px;
  }

  #about .header .real-name {
    font-size: 23px;
  }

  #about article p {
    font-size: 18px;
  }

  #about article p:not(:last-child) {
    margin-bottom: 25px;
  }

  main[data-barba-namespace="about"] + #rive.is-ready {
    padding-top: var(--about-padding-y-sm);
    padding-bottom: var(--about-padding-y-sm);
  }

  main[data-barba-namespace="about"] + #rive.is-ready #character {
    width: 50%;
  }
}

html, body {
  font-family: var(--font-body);
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  background-color: var(--color-peach-dark);
  -webkit-tap-highlight-color: transparent;
}

body.overflow-is-hidden {
  overscroll-behavior: none;
  overflow: hidden;
}

body.is-loading main, body.is-loading menu, body.is-loading #rive {
  visibility: hidden;
}

main {
  background-color: var(--color-peach);
  min-height: 100vh;
}

.section-title {
  font-family: var(--font-display);
  color: var(--color-peach-light);
  text-align: center;
  letter-spacing: -3px;
  text-transform: uppercase;
  line-height: 1;
  position: relative;
}

#bg-grid {
  --grid-stroke-width: 6px;
  --grid-width: 80px;
  --grid-stroke: var(--color-purple-dark);
  pointer-events: none;
  opacity: 0;
  background-color: var(--color-purple);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#bg-grid:before {
  content: "";
  background-image: linear-gradient(var(--grid-stroke) var(--grid-stroke-width), transparent var(--grid-stroke-width)), linear-gradient(to right, var(--grid-stroke) var(--grid-stroke-width), transparent var(--grid-stroke-width));
  background-size: var(--grid-width) var(--grid-width);
  opacity: .1;
  background-position: 0 0;
  animation: 3s linear infinite forwards grid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes grid-sm {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -50px -50px;
  }
}

@keyframes grid {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -80px -80px;
  }
}

@media screen and (max-width: 767px) {
  .section-title {
    letter-spacing: 0;
    font-size: 70px !important;
  }
}

.is-hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

/*# sourceMappingURL=about.75a14a41.css.map */

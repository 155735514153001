@keyframes squiggly {
  0% {
    filter: url("#squiggly-0");
  }
  25% {
    filter: url("#squiggly-1");
  }
  50% {
    filter: url("#squiggly-2");
  }
  75% {
    filter: url("#squiggly-3");
  }
  100% {
    filter: url("#squiggly-4");
  }
}

#squiggly {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

#rive {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding-bottom: 10vh;

  &:before {
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    width: 25%;
    aspect-ratio: 1/1;
    transform: translate(-50%, -50%);
  }

  &.show-sc-only {
    .canvas-wrapper:not(#character) {
      visibility: hidden;
    }
  }

  .inner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 25%; // same width as design
    position: relative;
    // will-change: transform;
  }

  canvas:not(#transition-overlay) {
    width: 100%;
    position: relative;
  }

  canvas:not(.artboard-sc):not(.artboard-music) {
    pointer-events: none;
  }

  .look-trigger, .page-trigger {
    position: absolute;
    border-radius: 3%;
    background-color: red;
    opacity: 0;
  }
}

.canvas-wrapper {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: red;
    display: none;
  }
}

.canvas-wrapper:not(#character) {
  position: absolute;
  width: 90%;
  left: 0;
  bottom: 0;
}





#character {
  position: relative;
  transform-origin: bottom;

  &.is-jumping {
    #shirt-trigger {
      display: none;
    }
  }

  #shirt-trigger {
    opacity: 0;
    position: absolute;
    width: 17%;
    height: 16%;
    background-color: red;
    top: 61%;
    left: 50%;
    border-top-left-radius: 15%;
    border-top-right-radius: 15%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  // floor
  #floor {
    position: absolute;
    bottom: 0;
    left: -110%;
    right: -110%;
    height: 1.15%;
    transform-origin: center;
    background-color: rgba(0,0,0,.15);
    // background-color: #f1c0ce;
    border-radius: 10px;
    z-index: -1;
  }
}

#bubbles {
  position: absolute;
  top: 15%;
  left: -25%;
  right: -25%;
  display: none;

  .bubble {
    background-color: #248bf5;
    color: white;
    font-size: 20px;
    padding: 15px 30px;
    border-radius: 100px;
    border-bottom-right-radius: 0;
    display: inline-block;
  }
}

#desk.canvas-wrapper {
  left: -105%;

  .email-trigger {
    width: 23%;
    height: 14%;
    top: 7%;
    left: 45%;
    right: 20%;
    border-radius: 10px;
  }

  .work-trigger {
    width: 62%;
    height: 31.85%;
    top: 27.25%;
    left: 20%;
    padding: 3px 10px 3px 4px;
    transition: .3s transform cubic-bezier(.34, 2.14, .64, 1);
    &:hover {
      transition: .5s transform cubic-bezier(.16, 1, .3, 1);
      transform: translateY(-4%);
    }
  }

  .screen {
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: .3s all ease;
    position: relative;
  }

  .content {
    color: white;
    letter-spacing: 2;
    font-size: 200%;
  }

  &.is-monitor-on {
    .screen {
      opacity: 1;
    }
  }
}

#music.canvas-wrapper {
  left: unset;
  right: -92.5%;

  .guitar-wrapper {
    position: absolute;
    width: 24.15%;
    top: 2.2%;
    left: 7.7%;
    opacity: 0;
  }

  .sound-trigger {
    border-radius: 10px;
    width: 15.1%;
    height: 20%;
    top: 46.5%;
    left: 57.3%;
    cursor: pointer;
  }
}

#social-resume.canvas-wrapper {
  width: 62.5%;
  bottom: unset;
  left: unset;
  top: -40%;
  right: -120%;
  .social-links {
    position: absolute;
    top: 8%;
    left: 0%;
    width: 91%;
    height: 27%;
    .page-trigger {
      top: 24%;
      height: 52%;
      left: 9.5%;
      width: 24%;
      border-radius: 10%;
      &:nth-child(2) {
        left: 38%;
      }
      &:nth-child(3) {
        left: 66.5%;
      }
    }
  }

  .resume-trigger {
    width: 50%;
    height: 25%;
    bottom: 4.5%;
    left: 21.5%;
    border-radius: 7%;
    transition: .3s transform cubic-bezier(.34, 2.14, .64, 1);
    &:hover {
      transition: .5s transform cubic-bezier(.16, 1, .3, 1);
      transform: translateY(-6%);
    }
  }
}

#about-sc.canvas-wrapper {
  width: 50%;
  bottom: unset;
  left: -90%;
  right: unset;
  top: -50%;

  .about-trigger {
    top: 29%;
    left: 22%;
    right: 22%;
    bottom: 19%;
    // opacity: .5;
  }
}

// breakpoints

@media screen and (max-width: 768px) {
  #rive {
    padding-bottom: 18vh;
    padding-bottom: 18dvh;
    .inner {
      width: 80%;
    }
  }
}

@media screen and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  #rive {
    padding-bottom: 8vh;
    .inner {
      width: 70%;
    }
  }
}

@media (orientation: portrait) or (max-width: 768px) {
  .canvas-wrapper:not(#character) {
    visibility: hidden;
  }

  #character {
    #floor {
      left: 20%;
      right: 20%;
    }
  }
}
#about {
  padding: var(--about-padding-y) var(--about-padding-x);
  position: relative;

  .content-wrapper {
    width: var(--about-content-width);
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-size: 4vw;
    line-height: 1.25;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-family: var(--font-display);
    font-weight: 900;
    letter-spacing: 1.2;
  }

  article {
    p {
      font-size: 20px;
      line-height: 1.65;
      font-weight: 300;
      font-weight: 400;
    }
    p:not(:last-child) {
      margin-bottom: 35px;
    }
  }

  .content {
    // visibility: hidden;
  }
}

#about .header {
  margin-bottom: 60px;
  display: flex;
  flex-wrap: wrap;
  
  .avatar-wrapper {
    display: block;
    aspect-ratio: 1;
    // background-color: var(--color-peach-light);
    border-radius: 50%;
    width: 32%;
  }

  .avatar-info {
    width: 68%;
    padding-left: 50px;
    display: grid;
    align-items: center;
  }

  .name {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 900;
  }

  .real-name {
    font-size: 25px;
    font-weight: 600;
    color: #666;
    display: inline-flex;
    line-height: 1.5;
    align-items: center;
    cursor: help;

    svg {
      display: block;
      width: 20px;
      position: relative;
      margin-left: 10px;
      top: 1px;
    }
  }

  .details {
    margin-top: 20px;
    font-size: 22px;
    font-weight: 400;
  }
}





// RIVE
main[data-barba-namespace="about"] {
  position: relative;
}

main[data-barba-namespace="about"] + #rive {
  padding-bottom: 0;
  height: 100%;
  top: 0;
  display: grid;
  align-items: center;
  pointer-events: none;

  .inner {
    width: 50%;
  }

  #floor {
    left: 20%;
    right: 20%;
    opacity: 0;
  }

  &.is-ready {
    position: absolute;
    height: unset;
    bottom: unset;
    top: 0;
    padding: var(--about-padding-y) var(--about-padding-x);
    overflow: hidden;

    .inner {
      width: var(--about-content-width);
      margin-left: auto;
      margin-right: auto;
    }

    #character {
      width: 32%;
      pointer-events: auto;
      canvas {
        width: 200%;
        position: relative;
        margin-left: -50%;
        margin-top: -50%;
        clip-path: polygon(25% 1%, 75% 0, 75% 80%, 25% 80%);
        background-color: var(--color-peach);
        // this clip path cut the hoverable area
        // so it won't be hoverable 25% on the right, left, and bottom sides
      }
    }
  }
}





// breakpoints
@include below(lg) {
  #about {
    .content-wrapper {
      width: 80%;
    }
  }  
  main[data-barba-namespace="about"] + #rive {
    .inner {
      width: 80%;
    }
  }
  main[data-barba-namespace="about"] + #rive.is-ready {
    .inner {
      width: 80%;
    }
  }
}

@include below(md) {
  #about {
    .content-wrapper {
      width: 100%;
    }
  }
  main[data-barba-namespace="about"] + #rive {
    .inner {
      width: 100%;
    }
  }
  main[data-barba-namespace="about"] + #rive.is-ready {
    .inner {
      width: 100%;
    }
  }
}

@include below(sm) {
  #about {
    padding-top: var(--about-padding-y-sm);
    padding-bottom: 140px;
    .header {
      display: block;
      margin-bottom: 50px;
      .avatar-wrapper {
        width: 50%;
        margin-bottom: 30px;
      }
      .avatar-info {
        width: 100%;
        padding-left: 0;
      }
      .name {
        font-size: 35px;
      }
      .real-name {
        font-size: 23px;
      }
    }

    article {
      p {
        font-size: 18px;
      }
      p:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }

  main[data-barba-namespace="about"] + #rive.is-ready {
    padding-top: var(--about-padding-y-sm);
    padding-bottom: var(--about-padding-y-sm);
    #character {
      width: 50%;
    }
  }
}
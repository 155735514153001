main[data-barba-namespace="work"] + #rive {
  pointer-events: none;
}

#work {
  #work-title {
    font-size: 7vw;
    letter-spacing: -1;
    margin-bottom: 60px;
  }

  .inner {
    padding: 50px 50px 200px;
  }
}

.work-grid {
  --card-gap: 1vw;
  display: grid;
  grid-gap: var(--card-gap);
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
}

.cards {
  display: grid;
  grid-gap: var(--card-gap);
  align-items: flex-start;
  position: relative;
}

.card {
  border-radius: 20px;
  box-shadow: 0px 5px 5px #e9bfcb85;
  overflow: hidden;
  position: relative;
  color: white;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: black;
    opacity: 0;
    pointer-events: none;
    transition: .3s all ease;
  }

  &.is-dim {
    &:after {
      opacity: .75;
    }
  }

  &.is-loaded {
    .card-tags {
      visibility: visible;
    }
    .card-details:before {
      visibility: visible;
    }
    video {
      visibility: visible;
    }
  }

  &.theme-dark {
    .card-details {
      color: white;
    }
    &.is-loaded {
      .card-details:before {
        visibility: hidden;
      }
    }
  }

  &.theme-light {
    .card-details {
      color: black;
    }
    &.is-loaded {
      .card-details:before {
        visibility: hidden;
      }
    }
  }

  .card-info {
    display: flex;
    justify-content: space-between;
  }
  
  .card-tags {
    display: inline-flex;
    visibility: hidden;
    margin-bottom: 10px;
    .pill:not(:last-child) {
      margin-right: 10px;
    }
  }
  
  .pill {
    display: inline-block;
    color: white;
    padding: 4px 15px;
    border-radius: 50px;
    font-size: 12px;
    transition: .3s all ease;
    opacity: 0;
    transform: translateY(5px);
    text-transform: capitalize;
  }
  
  .type-link {
    background-color: white;
    height: 26px;
    width: 26px;
    padding: 0;
    display: grid;
    align-items: center;
    svg {
      width: 13px;
      display: block;
      margin: auto;
      transform-origin: center;
      transform: rotate(135deg);
    }
  }
  
  .type-website {
    background-color: var(--color-peach-dark);
  }
  
  .type-prototype {
    background-color: #c97ccf;
  }
  
  .card-details {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 15px 20px;
    font-size: 14px;
  
    div {
      position: relative;
    }
  
    &:before {
      content: '';
      pointer-events: none;
      position: absolute;
      inset: 0;
      transform-origin: center;
      transform: scaleY(1.5);
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), 50%, rgba(0, 0, 0, 0));
      visibility: hidden;
    }
  }
  
  video, img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  
  .thumbnail {
    filter: blur(32px);
    transform: scale(1) translateZ(0px);
  }
  
  video {
    position: absolute;
    inset: 0;
    visibility: hidden;
  }
}

@include above(md) {
  .card {
    &:hover {
      .pill {
        transform: none;
        opacity: 1;
      }
      .pill:nth-child(2) {
        transition-delay: .1s;
      }
    }
  }
}

@include below(lg) {
  #work {
    .inner {
      padding: 25px;
    }
  }
  .work-grid {
    --card-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@include below(md) {
  #work {
    padding-bottom: 100px;
    .inner {
      padding: 15px;
    }
  }
  .work-grid {
    --card-gap: 15px;
    grid-template-columns: repeat(1, 1fr);
    .card {
      border-radius: 10px;
    }
  }
}
@import 'utils/mdqs';

@import 'base/flickity.css';
@import 'base/reset';
@import 'base/variables';
@import 'base/container';

@import 'components/loader';
@import 'components/header';
@import 'components/rive';

@import 'pages/home';
@import 'pages/work';
@import 'pages/about';

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: var(--font-body);
}

body {
  background-color: var(--color-peach-dark);
  -webkit-tap-highlight-color: transparent;

  &.overflow-is-hidden {
    overflow: hidden;
    overscroll-behavior: none;
  }

  // is not loaded
  &.is-loading {
    main, menu, #rive {
      visibility: hidden;
    }
  }
}

main {
  min-height: 100vh;
  background-color: var(--color-peach);
}

.section-title {
  font-family: var(--font-display);
  color: var(--color-peach-light);
  text-align: center;
  line-height: 1;
  letter-spacing: -3;
  position: relative;
  text-transform: uppercase;
}

#bg-grid {
  --grid-stroke-width: 6px;
  --grid-width: 80px;
  --grid-stroke: var(--color-purple-dark);

  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  background-color: var(--color-purple);

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: linear-gradient(var(--grid-stroke) var(--grid-stroke-width), transparent var(--grid-stroke-width)), linear-gradient(to right, var(--grid-stroke) var(--grid-stroke-width), transparent var(--grid-stroke-width));
    background-size: var(--grid-width) var(--grid-width);
    background-position: 0 0;
    animation: grid forwards 3s infinite;
    animation-timing-function: linear;
    opacity: .1;
  }
}

@keyframes grid-sm {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -50px -50px;
  }
}

@keyframes grid {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -80px -80px;
  }
}

@include below(md) {
  .section-title {
    font-size: 70px !important;
    letter-spacing: 0;
  }
}

.is-hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
} 